<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          rounded="sm"
          spinner-variant="primary"
        >
          <b-card
            class="text-center"
          >
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2 d-flex justify-space-between">
                <span>Manage {{ program.title }}</span>
              </h3>
            </b-card-header>
            <b-tabs
              v-model="activeTab"
              class="h-scroll-tabs"
              nav-class="mb-2"
              pills
              @input="updateHash"
            >
              <b-tab
                active
                href="Startups"
                lazy
                title="Startups"
              >
                <b-card
                  class="text-left"
                  title="Manage Startups"
                >
                  <startup-progress />
                </b-card>
              </b-tab>
              <b-tab
                title-item-class="calendarbtn"
                class="text-left"
                href="Activities"
                lazy
                title="Calendar"
              >
                <scheduler
                  v-if="!$apollo.loading"
                  :begin_date="program.begin_date"
                  :end_date="program.end_date"
                />
              </b-tab>
              <b-tab
                class="text-left"
                href="Mentors"
                lazy
                title="Mentors"
              >
                <mentor-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Modules"
                lazy
                title="Modules"
              >
                <module-manager />
              </b-tab>
              <b-tab
                href="Workshops"
                lazy
                title="Workshops"
              >
                <workshop-manager />
              </b-tab>
              <b-tab
                href="MasterClass"
                lazy
                title="Masterclass"
              >
                <b-card
                  class="text-left"
                  title="Manage Masterclasses"
                >
                  <master-class-manager />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="E-Meet"
                lazy
                title="Sessions"
              >
                <b-card
                  class="text-left"
                  title="Manage Sessions"
                >
                  <emeet-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="Exercises"
                lazy
                title="Exercises"
              >
                <b-card
                  class="text-left"
                  title="Manage Exercises"
                >
                  <exercise-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="KnowledgeBase"
                lazy
                title="Library"
              >
                <b-card
                  class="text-left"
                  title="Manage Library"
                >
                  <knowledge-base />
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import MentorManager from '@/views/incubator-portal/programs/pages/startups/program-manager/MentorManager.vue'
import Scheduler from '@/views/incubator-portal/programs/pages/common/program-manager/Scheduler.vue'
import StartupProgress from '@/views/incubator-portal/programs/pages/startups/program-manager/StartupProgress.vue'
import ExerciseManager from '@/views/incubator-portal/programs/pages/startups/program-manager/ExerciseManager.vue'
import EmeetManager from '@/views/incubator-portal/programs/pages/startups/program-manager/EmeetManager.vue'
import WorkshopManager from '@/views/incubator-portal/programs/pages/startups/program-manager/WorkshopManager.vue'
import MasterClassManager from '@/views/incubator-portal/programs/pages/startups/program-manager/MasterClassManager.vue'
import KnowledgeBase from '@/views/incubator-portal/programs/pages/common/program-manager/Library.vue'
import ModuleManager from '@/views/incubator-portal/programs/pages/startups/program-manager/ModuleManager.vue'

export default {
  components: {
    EmeetManager,
    WorkshopManager,
    ModuleManager,
    MasterClassManager,
    ExerciseManager,
    StartupProgress,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BTab,
    BTabs,
    BButton,
    // ManageFeedbacks,
    MentorManager,
    Scheduler,
    KnowledgeBase,
    // ProgressStages,
    // RequestsManager,
    // FeedbackManager,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      program: '',
      activeTab: 0,
      tabs: ['#Startups',
        '#Activities',
        '#Mentors',
        '#Modules',
        '#Workshops',
        '#MasterClass',
        '#Sessions',
        '#Exercises',
        '#Library',
        '#Feedback',
        '#Services',
        '#Requests'],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      if ((this.tabs[e] === '#Workshops' || this.tabs[e] === '#Sessions' || this.tabs[e] === '#MasterClass') && this.$route.params.mid) {
        this.$router.replace(this.$route.path + this.tabs[e])
      } else if (this.tabs[e] === '#Exercises' && this.$route.params.wid) {
        this.$router.replace(this.$route.path + this.tabs[e])
      } else {
        this.$router.replace(`/programs/${this.$route.params.id}/manage${this.tabs[e]}`)
      }
    },

    switchTab(tabname) {
      this.activeTab = this.tabs.findIndex(tab => tab === tabname)
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          id
          title
          begin_date
          end_date
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.calendarbtn{
  position: absolute;
  right: 20px;
  top: 20px;
  border: solid 1px #7367F0;
  border-radius: 8px;
}
</style>
